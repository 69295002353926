import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import swal from "sweetalert";
import { CropEasy } from "../components/crop/utils/CropEasy";
import eye from "../images/eye-solid.svg";
import userDefaultImage from "../images/userDefaultImage.jpg";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { Link, useNavigate } from "react-router-dom";

function Register() {
	useValidateWithoutLogin();
	const navigate = useNavigate()
	const [image, setImage] = useState(userDefaultImage);
	const [email, setEmail] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [loader, setLoader] = useState(false);

	const handleChange = (event) => {
		setEmail(event.target.value);
		setIsValid(validateEmail(event.target.value));
	};

	const validateEmail = (email) => {
		// A regular expression to validate email format
		const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		return emailRegex.test(email);
	};

	const [Pass1, setPass1] = useState("");
	const [Pass2, setPass2] = useState("");
	const [name, setName] = useState("");

	const [file, setFile] = useState([]);

	const [checkBox, setCheckBox] = useState(false);

	async function Register() {
		setLoader(true);
		if (name == "") {
			swal("Please enter Name");
			setLoader(false);
			return;
		}
		if (email == "") {
			swal("Please enter Email");
			setLoader(false);
			return;
		}
		if (isValid == false) {
			swal("Choose a Correct Email");
			setLoader(false);
			return;
		}
		if (Pass1.length < 8) {
			swal("Please enter minimum 8 characters");
			setLoader(false);
			return;
		}
		if (Pass1 != Pass2) {
			swal("write correct Password");
			setLoader(false);
			return;
		}
		if (checkBox == false) {
			swal("Please Accept Terms and Conditions");
			setLoader(false);
			return;
		}

		let formData = new FormData();
		formData.append("image", file);
		formData.append("email", email);
		formData.append("password", Pass1);
		formData.append("full_name", name);
		formData.append("user_type", "user");
		let response = await fetch(process.env.REACT_APP_BASE_URL + "/auth/register", {
			method: "POST",
			body: formData,
		});
		let result = await response.json();

		if (result.status == true) {
			navigate("/login");
			setLoader(false);
		} else {
			swal(result.message);
			setLoader(false);
		}
	}

	const [eyeV, setEyeV] = useState("password");
	function visible() {
		if (eyeV == "password") setEyeV("text");
		else setEyeV("password");
	}
	const [eyeV2, setEyeV2] = useState("password");
	function visible2() {
		if (eyeV2 == "password") setEyeV2("text");
		else setEyeV2("password");
	}

	const [preview, setPreview] = useState("");

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
			setPhotoURL(URL.createObjectURL(file));
			setOpenCrop(true);
		}
		const reader = new FileReader();

		reader.onloadend = () => {
			setPreview(reader.result);
		};

		if (file) {
			reader.readAsDataURL(file);
		} else {
			setPreview("");
		}
		e.target.value = null;
	};

	return (
		<div className="register">
			{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
			<div className="left">
				<div className="leftHeading">Teach your audiance what they want to learn</div>
				<br />
				<br />
				<br />
				<br />
				<div className="dots">
					<div className="dot-purple"></div>
					<div className="dot-gray"></div>
					<div className="dot-gray"></div>
				</div>
			</div>

			<div className="Registercard card" >
				<div className="card-body RegisterUserContainer">
					<h1 className="card-title">REGISTER</h1>
					<div>
						{photoURL ? (
							<img src={photoURL} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						) : (
							<img src={image} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						)}
						<input type="file" id="image-input" onChange={handleFileInputChange} />
					</div>
					<input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
					<input type="email" name="email" id="email" placeholder="Email" onChange={handleChange} />
					<div className="regDivUser">
						<input
							className="eye-pass"
							type={eyeV}
							placeholder="Password"
							onChange={(e) => setPass1(e.target.value)}
						/>
						<img src={eye} className="eye-register" onClick={visible} style={{ cursor: "pointer" }} alt="" />
					</div>
					<div className="regDivUser">
						<input
							className="eye-pass"
							type={eyeV2}
							placeholder="Password"
							onChange={(e) => setPass2(e.target.value)}
						/>
						<img src={eye} className="eye-register" onClick={visible2} style={{ cursor: "pointer" }} alt="" />{" "}
					</div>
					<div className="display-flex">
						<div>
							<input
								type="checkbox"
								onChange={(event) => setCheckBox(event.target.checked)}
								style={{ height: "15px", marginTop: "10px", marginLeft: "15px" }}
							/>
						</div>
						<div style={{ marginLeft: "15px" }}>
							<p className="card-text">
								I Agree to <Link to="/terms-condition">terms & conditions </Link>and{" "}
								<Link to="/privacy">privacy policy</Link>
							</p>
						</div>
					</div>
					<button
						className="loginWithEmail"
						onClick={Register}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						{loader ? <div className="ring"></div> : <>Continue</>}
					</button>
					<p className="card-text">
						I already have an account <a href="/login">Login</a>
					</p>
				</div>
			</div>
		</div>
	);
}
export default Register;

import axios from "axios";

const Instance = axios.create({
	// baseURL: "https://businesspersonapp.emagz.live/api/v1",
	baseURL: "https://musicbook-api-1.vercel.app/api/v1",
	// baseURL: "http://localhost:3666/api/v1",
	headers: {
		authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
	},
});

export default Instance;

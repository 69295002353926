import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VideoThumbnail from "react-video-thumbnail";
import { createNotification } from "../components/Hooks/CreateNotification";
import axios from "../components/Hooks/axios";
import useHomeValidate from "../components/Hooks/useHomeValidate";
import LiveAudio from "../components/LiveAudio";
import Payment from "../components/Payment/Payment";
import Post from "../components/Post";
import { CropEasy } from "../components/crop/utils/CropEasy";
import styles from "../css/UserHome.module.scss";
import CreatePost from "./createPostMobile";
import Header from "./header.component";
import MobileHeader from "./mobileHeader";

function HomePage() {
	var URLS = window.URL || window.webkitURL;
	useHomeValidate();
	const navigate = useNavigate();

	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	const [name, setName] = useState("");
	const [postUploadBtn, setPostUploadBtn] = useState(false);
	const { userId } = useSelector((state) => state.auth);
	const [caption, setCaption] = useState("");
	const [file, setFile] = useState([]);
	const [filetype, setFiletype] = useState("");
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [clearDisable, setClearDisable] = useState(true);
	const imageRef = useRef(null);
	const videoRef = useRef(null);
	const [offsetTopAlbum, setOffsetTopAlbum] = useState(0);
	const [album, setAlbum] = useState([]);
	const [albumLoading, setAlbumLoading] = useState(false);
	const [myLearning, setMyLearning] = useState([]);
	const [loadingMyLearning, setLoadingMyLearning] = useState(false);
	const [LiveAudioShow, setLiveAudioShow] = useState(false);
	const [url, setUrl] = useState("");
	const [imageBase64, setImageBase64] = useState("");
	const [thumbKey, setThumbKey] = useState("")

	useEffect(() => {
		setAlbumLoading(true);
		axios.get(`/album/getLatestAlbumUser?offset=${offsetTopAlbum}`).then(({ data }) => {
			if (data.status == true) {
				setAlbum(data.data);
				setAlbumLoading(false);
			} else {
				console.log("incorrect");
			}
		});
	}, [offsetTopAlbum]);

	useEffect(() => {
		setLoadingMyLearning(true);
		if (userId === "") return;
		axios
			.get(`/class/getEnrollCourse/${userId}`)
			.then(({ data }) => {
				if (data.status) {
					setName(data.data[0].full_name);
					setMyLearning(data.data[0].enrolled);
					setLoadingMyLearning(false);
				}
			})
			.catch((e) => {
				setLoadingMyLearning(false);
				console.log(e);
			});
	}, [userId]);

	const onImageChange = (e) => {
		const file = e.target.files[0];
		if (!["jpg", "png", "jpeg","webp"].includes(file.name.split(".")[e.target.files[0].name.split(".").length - 1].toLowerCase())) {
			e.target.value = null;
			return toast.error("This type of file not supported in this button");
		}
		if (file) {
			setFile(file);
			setFiletype("image");
			setPhotoURL(URL.createObjectURL(file));
			setOpenCrop(true);
			setClearDisable(false);
		}
		e.target.value = null;
	};

	const onImageChange2 = (e) => {
		const file = e.target.files[0].name.split(".");
		setThumbKey(file[0]);
		if (!["mp4"].includes(e.target.files[0].name.split(".")[file.length - 1].toLowerCase())) {
			e.target.value = null;
			return toast.error("This type of file not supported in this button");
		}
		if (Math.round(e.target.files[0].size / 1024 / 1024) > 101) {
			e.target.value = null;
			return toast.warn("Please select 100mb or less video size");
		}
		setFile(e.target.files[0]);

		var fileURL = URL.createObjectURL(e.target.files[0]);
		setUrl(fileURL);

		setFiletype("video");
		setClearDisable(false);
		e.target.value = null;
	};

	const onImageChange3 = (e) => {
		if (!["mp3"].includes(e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1].toLowerCase())) {
			e.target.value = null;
			return toast.error("This type of file not supported");
		}
		setFile(e.target.files[0]);
		console.log(e.target.files[0]);
		setFiletype("audio");
		setClearDisable(false);
		e.target.value = null;
	};

	const audioRecording = (e) => {
		setFile(e);
		setFiletype("audio");
		setClearDisable(false);
	};

	const handelClearAction = () => {
		setFiletype("");
		setFile([]);
		setCaption("");
		setClearDisable(true);
	};

	const [uploadingPercent, setUploadingPercent] = useState(0);
	const [visibleProgress, setVisibleProgress] = useState("0%");
	const [reload, setReload] = useState(false);

	function Upload() {
		if (caption === "" && file.length === 0) return toast.warn("Please add a caption or image/video/audio");
		if (caption === "" && filetype === "video") return toast.warn("Please add a caption");
		if (caption === "" && filetype === "audio") return toast.warn("Please add a caption");
		setPostUploadBtn(true);

		if (filetype === "video") {
			let formData = new FormData();
			formData.append("media", file);
			formData.append("caption", caption);
			formData.append("tags", "");
			formData.append("user_id", localStorage.getItem("user_id"));
			formData.append("type", filetype);
			formData.append(thumbKey, imageBase64); // key => video file name FIXME:
			formData.append("upload_from", "web"); //testing thumbnail in video

			setVisibleProgress("100%");

			axios
				.post("post/create", formData, {
					headers: { authorization: localStorage.getItem("auth_token") },
					onUploadProgress: (data) => {
						setUploadingPercent(Math.round((data.loaded / data.total) * 100));
					},
				})
				.then((success) => {
					toast.success("Post Created Successfully");
					createNotification(userId, "Post", "Video Uploaded Successfully");
					setCaption("");
					setFile([]);
					setFiletype("");
					setPostUploadBtn(false);
					setPage(0);
					setReload(true);
					setUploadingPercent(0);
					setVisibleProgress("0");
				})
				.catch((error) => {
					toast.error("error");
					setUploadingPercent(0);
					setVisibleProgress("0");
				});
		} else {
			if (filetype === "audio" && caption === "") {
				setPostUploadBtn(false);
				return toast.warn("Please add any caption");
			}
			let formData = new FormData();
			formData.append("media", file);
			formData.append("caption", caption);
			formData.append("tags", "post");
			formData.append("user_id", localStorage.getItem("user_id"));
			formData.append("type", filetype);

			setVisibleProgress("100%");

			axios
				.post("/post/create", formData, {
					headers: { authorization: localStorage.getItem("auth_token") },
					onUploadProgress: (data) => {
						setUploadingPercent(Math.round((data.loaded / data.total) * 100));
					},
				})
				.then((success) => {
					toast.success("Post Created Successfully");
					console.log(filetype);
					if (filetype === "") createNotification(userId, "Post", "Caption Uploaded Successfully");
					if (filetype === "audio") createNotification(userId, "Post", "Audio Uploaded Successfully");
					if (filetype === "image") createNotification(userId, "Post", "Image Uploaded Successfully");
					setCaption("");
					setFile([]);
					setFiletype("");
					setPhotoURL("");
					setPostUploadBtn(false);
					setPage(0);
					setReload(true);
					setUploadingPercent(0);
					setVisibleProgress("0");
				})
				.catch((error) => {
					toast.error("error");
					setUploadingPercent(0);
					setVisibleProgress("0");
					alert("hii")
				});
		}
	}

	const [page, setPage] = useState(0);

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setPage((prev) => prev + 10);
		}
	}

	const slider2 = document.querySelector(".LearningCont");
	let isDown2 = false;
	let startX2;
	let scrollLeft2;

	slider2?.addEventListener("mousedown", (e) => {
		isDown2 = true;
		startX2 = e.pageX - slider2.offsetLeft;
		scrollLeft2 = slider2.scrollLeft;
	});
	slider2?.addEventListener("mouseleave", () => {
		isDown2 = false;
	});
	slider2?.addEventListener("mouseup", () => {
		isDown2 = false;
	});
	slider2?.addEventListener("mousemove", (e) => {
		if (!isDown2) return;
		e.preventDefault();
		const x2 = e.pageX - slider2.offsetLeft;
		const walk2 = (x2 - startX2) * 2; //scroll-fast
		slider2.scrollLeft = scrollLeft2 - walk2;
	});

	const slider = document.querySelector(".albumCont");
	let isDown = false;
	let startX;
	let scrollLeft;

	slider?.addEventListener("mousedown", (e) => {
		isDown = true;
		startX = e.pageX - slider.offsetLeft;
		scrollLeft = slider.scrollLeft;
	});
	slider?.addEventListener("mouseleave", () => {
		isDown = false;
	});
	slider?.addEventListener("mouseup", () => {
		isDown = false;
	});
	slider?.addEventListener("mousemove", (e) => {
		if (!isDown) return;
		e.preventDefault();
		const x = e.pageX - slider.offsetLeft;
		const walk = (x - startX) * 2; //scroll-fast
		slider.scrollLeft = scrollLeft - walk;
	});

	const [payment, setPayment] = useState(false);

	return (
		<div className={styles.UserHomeMain}>
			<div className={styles.design}></div>
			{payment && <Payment setPayment={setPayment} />}
			<Header />
			<MobileHeader />
			<CreatePost />
			{LiveAudioShow && (
				<LiveAudio setLiveAudioShow={setLiveAudioShow} onImageChange3={onImageChange3} audioRecording={audioRecording} />
			)}
			<div className={styles.UserHome}>
				<div className="text-info m-2">Scribble </div>
				<hr className="text-info" style={{ width: "90px", marginTop: "-10px" }} />
				<div className={styles.HomeContainer}>
					<div className={styles.HomeLeft} onScroll={ReachBottom}>
						<span>Hello,</span>
						<h1>{name}</h1>
						<Post page={page} setPage={setPage} reload={reload} setReload={setReload} />
					</div>

					<div className={`${styles.HomeRight}`}>
						{openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />}
						<div className={`home-right-top ${styles.scribbleContainer}`}>
							<h6>Scribble here</h6>
							<div
								className="my-custom-clear-btn"
								style={{
									background: "rgb(20, 101, 168)",
									color: "#fff",
									display: filetype ? "flex" : "none",
								}}>
								<button
									className="btn"
									onClick={handelClearAction}
									disabled={clearDisable}
									style={{ border: filetype ? "none" : "" }}>
									Clear
								</button>
							</div>
							<textarea
								value={caption}
								placeholder="Poem, Shayari, Lyrics, songs, motivational content and other musical works in Audio, Video, text or Image format."
								onChange={(e) => setCaption(e.target.value)}></textarea>

							<div className="ScribbleButtonMainContainer">
								<div className="ScribbleButtonContainer">
									<button
										className="ScribbleButtonInput"
										onClick={() => imageRef.current.click()}
										style={{
											background: filetype == "image" ? "rgb(20, 101, 168)" : "",
											border: filetype == "image" ? "none" : "",
										}}>
										Image
									</button>
									<input
										type="file"
										name="myfile"
										ref={imageRef}
										accept="image/*"
										onChange={onImageChange}
										style={{ display: "none" }}
									/>
								</div>
								<div className="ScribbleButtonContainer">
									<button
										className="ScribbleButtonInput"
										onClick={() => videoRef.current.click()}
										style={{
											background: filetype == "video" ? "rgb(20, 101, 168)" : "",
											border: filetype == "video" ? "none" : "",
										}}>
										Video
									</button>
									<input
										type="file"
										name="myfile"
										ref={videoRef}
										accept="video/mp4"
										onChange={onImageChange2}
										style={{ display: "none" }}
									/>
								</div>
								<div className="ScribbleButtonContainer">
									<button
										className="ScribbleButtonInput"
										onClick={() => setLiveAudioShow(!LiveAudioShow)}
										style={{
											background: filetype == "audio" ? "rgb(20, 101, 168)" : "",
											border: filetype == "audio" ? "none" : "",
										}}>
										Audio
									</button>
								</div>

								<div
									className="ScribbleButtonContainer"
									style={{ background: filetype == "camera" ? "rgb(20, 101, 168)" : "" }}>
									<button className="ScribbleButtonInput">Camera</button>
								</div>

								<div className="ScribbleButtonContainer">
									<button
										className="ScribbleButtonInput"
										style={{
											background: filetype !== "" || caption !== "" ? "#471CF1" : "",
											border: filetype !== "" || caption !== "" ? "none" : "",
											color: filetype !== "" || caption !== "" ? "#fff" : "",
										}}
										disabled={postUploadBtn}
										onClick={Upload}>
										Post
									</button>
								</div>
								{/* <button className="btn btn-outline-primary">Live</button> */}
							</div>
							<div className="progress" style={{ opacity: visibleProgress }}>
								<div
									className="progress-bar"
									role="progressbar"
									style={{ width: `${uploadingPercent}%` }}
									aria-valuenow={uploadingPercent}
									aria-valuemin="0"
									aria-valuemax="100">
									{uploadingPercent}%
								</div>
							</div>
						</div>

						<div style={{ display: "none" }}>
							<VideoThumbnail
								videoUrl={url}
								thumbnailHandler={(thumbnail) => {
									// const binaryData = atob(thumbnail.split(",")[1]);
									// const uint8Array = new Uint8Array(binaryData.length);
									// for (let i = 0; i < binaryData.length; i++) {
									// 	uint8Array[i] = binaryData.charCodeAt(i);
									// }
									// const blob = new Blob([uint8Array], { type: "application/octet-stream" });
									// const file = new File([blob], "thumbnail.png", { type: "application/octet-stream" });
									// setImageBase64(file);
									fetch(thumbnail)
										.then((res) => res.blob())
										.then((blob) => {
											const file = new File([blob], "File name", { type: "image/png" });
											setImageBase64(file);
										});
								}}
								width={1920}
								snapshotAtTime={10}
								height={1040}
							/>
						</div>

						<div className={` ${styles.LearningMainContainer}`} style={{ display: "none" }}>
							<h5>
								My Learnings <Link to={"/learning"}>Go to learning</Link>
							</h5>
							<div className={`LearningCont ${styles.LearningBoxContainer}`}>
								{loadingMyLearning ? (
									<div className={styles.loadingDiv}>
										<ColorRing
											visible={true}
											height="100"
											width="100"
											ariaLabel="blocks-loading"
											wrapperClass="blocks-wrapper"
											colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
										/>
									</div>
								) : myLearning.length === 0 ? (
									"No Data"
								) : (
									myLearning.map((data, index) => (
										<div
											className={`${styles.lBox}`}
											key={index}
											onClick={() => navigate(`/learning/CourseDetails/${data._id}`)}>
											<h6>{data.courseTitle}</h6>
											<p>{data.courseDescription}</p>
											<div></div>
											<span>
												<svg
													width="11"
													height="11"
													viewBox="0 0 11 11"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M5.70151 10.2053C2.94001 10.2053 0.701508 7.96682 0.701508 5.20532C0.701508 2.44382 2.94001 0.205322 5.70151 0.205322C8.46301 0.205322 10.7015 2.44382 10.7015 5.20532C10.7015 7.96682 8.46301 10.2053 5.70151 10.2053ZM5.70151 9.20532C6.76237 9.20532 7.77979 8.7839 8.52993 8.03375C9.28008 7.2836 9.70151 6.26619 9.70151 5.20532C9.70151 4.14446 9.28008 3.12704 8.52993 2.3769C7.77979 1.62675 6.76237 1.20532 5.70151 1.20532C4.64064 1.20532 3.62323 1.62675 2.87308 2.3769C2.12294 3.12704 1.70151 4.14446 1.70151 5.20532C1.70151 6.26619 2.12294 7.2836 2.87308 8.03375C3.62323 8.7839 4.64064 9.20532 5.70151 9.20532ZM6.20151 5.20532H8.20151V6.20532H5.20151V2.70532H6.20151V5.20532Z"
														fill="#999999"
													/>
												</svg>

												{"On " +
													data.createdAt.split("T")[0] +
													" at " +
													data.createdAt.split("T")[1].split(".")[0]}
											</span>
										</div>
									))
								)}
							</div>
						</div>
						<div className={styles.LiveClassesContainer} style={{ display: "none" }}>
							<h5>Live Classes</h5>
							<div className={styles.LiveClassBoxContainer}>
								<AliceCarousel
									mouseTracking
									disableDotsControls
									disableButtonsControls
									responsive={{
										0: { items: 1 },
										1100: { items: 1.2 },
										1160: { items: 1.4 },
										1250: { items: 1.4 },
										1300: { items: 1.3 },
										1350: { items: 1.6 },
										1530: { items: 2 },
										1700: { items: 2.5 },
									}}>
									{Array(5)
										.fill("")
										.map((data, index) => (
											<div key={index} className={styles.ClassBox} onDragStart={(e) => e.preventDefault()}>
												<div className={styles.left}>
													<div className={styles.img}>
														<img src="https://picsum.photos/200/300" alt="" />
													</div>
													<div className={styles.text}>
														<h6>Rohan Mondal</h6>
														<p>@RohanMondal</p>
													</div>
												</div>
												<div className={styles.right}>
													<h1>Piano Class</h1>
													<div className={styles.date}>
														<span>Today</span>
														<p>18</p>
														<p>April</p>
														<p>2023</p>
														<p>Wednesday</p>
													</div>
													<p>
														Write notes on the music staff to create a song. Notes are made of note
														heads, stems .....
													</p>
													<div className={styles.bottom}>
														<button>Live</button>
														<p>12:30 min Remaining</p>
													</div>
												</div>
											</div>
										))}
								</AliceCarousel>
							</div>
						</div>
						<div className={styles.AlbumMainContainer} style={{ display: "none" }}>
							<h5>
								Recent Albums <Link to={"/album"}>Go to Album</Link>
							</h5>
							<div className={` albumCont ${styles.albumContainer}`}>
								{albumLoading ? (
									<div className={styles.loadingDiv}>
										<ColorRing
											visible={true}
											height="100"
											width="100"
											ariaLabel="blocks-loading"
											wrapperClass="blocks-wrapper"
											colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
										/>
									</div>
								) : (
									album.map((data, index) => (
										<div key={index} className={styles.ABox} onClick={() => navigate(`/album/${data._id}`)}>
											<img src={data.cover_photo} alt={`Album Image ${index + 1}`} />
										</div>
									))
								)}
							</div>
							<div className="AlbumPagination">
								<button
									disabled={offsetTopAlbum === 0 && true}
									onClick={() => setOffsetTopAlbum(offsetTopAlbum - 10)}>
									{"<"} Prev
								</button>
								<button
									disabled={album?.length < 10 && true}
									onClick={() => setOffsetTopAlbum(offsetTopAlbum + 10)}>
									Next {">"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default HomePage;
